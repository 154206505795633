<template>
  <div class="top-title">
    <slot name="left"></slot>
    <div class="right">
      <router-link to="/dashboard" class="top-title-link">控制台</router-link>
      <span class="br">/</span>
      <span class="right">
        <slot name="right"></slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopTitle",
};
</script>

<style lang="scss" scoped>
.top-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 10px 27px;
  color: #333333;
  font-weight: 700;
  color: #666;
  width: auto;
  font-size: 24px;
  .right {
    font-weight: 400;
    font-size: 18px;
    .top-title-link {
      color: #409eff;
    }
    .br {
      padding: 0 5px;
      color: #ccc;
    }
    .right {
      color: #666;
    }
  }
}
</style>
