import request from "./request"

//订单分页显示
export const orderPage = (page) => {
    return request("get", "/api/order/shopOrder/page", page);
}

//订单数据分析
export const analyzeData = () => {
    return request("get", "/api/order/shopOrder/analyzeData");
}

//订单详情
export const orderInfo = (id) => {
    return request("get", "/api/order/shopOrder/info", { id: id });
}

//微信支付
export const wxPay = (orderId) => {
    return request("post", "/pay/paywxconfig/pay", { payTypeEnum: "WXPAY_NATIVE", ordersn: orderId, amount: 0.01, orderName: "订单支付" });
}

//支付宝支付
export const aliPay = (orderId) => {
    return request("post", "/pay/payaliconfig/pay", { payTypeEnum: "ALIPAY_PC", ordersn: orderId, amount: 0.01, orderName: "订单支付" });
}