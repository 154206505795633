<!--  -->
<template>
  <div class="content">
    <top-title>
      <span slot="left">我的订单</span>
      <span slot="right">我的订单</span>
    </top-title>
    <div class="data border">
      <div class="inner" :style="{ 'max-height': height + 'px' }">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="接口" name="first"></el-tab-pane>
          <el-tab-pane label="框架" name="second"></el-tab-pane>
          <el-tab-pane label="会员" name="third"></el-tab-pane>
          <el-tab-pane label="加量包" name="fourth"></el-tab-pane>
          <el-table :data="tableData" style="width: 100%" v-loading="loading">
            <el-table-column fixed prop="orderSn" label="订单号" width="210"></el-table-column>
            <el-table-column prop="shopName" label="产品名称"></el-table-column>
            <el-table-column prop="createTime" label="创建时间"></el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <el-tag size="small" v-if="scope.row.status==1" type="danger">未支付</el-tag>
                <el-tag size="small" v-else type="success">已支付</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="payPrice" label="实付金额"></el-table-column>
            <el-table-column prop="orderPrice" label="订单金额"></el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <el-button @click="orderInfoHandler(scope.row.id)" type="text" size="small">详情</el-button>
                <el-button
                  v-if="scope.row.status == 1"
                  @click.native.prevent="payHandler(scope.row.id)"
                  type="text"
                  size="small"
                >去支付</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tabs>
      </div>
      <pagination
        :total="page.total"
        :current-page="page.current"
        :page-size="page.size"
        @refreshData="queryList"
      ></pagination>
    </div>
    <form-info v-if="orderInfoVisible" ref="orderInfo"></form-info>
  </div>
</template>

<script>
import formInfo from './order-info.vue'
import TopTitle from "@/components/common/top-title";
import FortressPagination from "@/components/common/pagination/pagination.vue";
import { orderPage } from "@/api/order.js";
export default {
  data () {
    return {
      orderInfoVisible: false,
      height: window.innerHeight * 0.7,
      memberId: "",
      page: {
        current: 1,
        size: 6,
        total: 0,
      },
      tableData: [],
      queryForm: {
        type: 1,
      },
      activeName: "first",
      loading: false,
    };
  },
  created () {
    this.queryList();
  },

  components: {
    formInfo,
    TopTitle,
    pagination: FortressPagination,
  },

  computed: {},

  methods: {
    queryList (page) {
      this.loading = true;
      this.tableData = [];
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      this.queryForm.current = this.page.current;
      this.queryForm.size = this.page.size;
      this.queryForm.memberId = JSON.parse(localStorage.getItem("userInfo")).id;
      orderPage(this.queryForm).then((resp) => {
        this.loading = false;
        if (resp.code == 0) {
          this.tableData = resp.data.records;
          this.page.total = resp.data.total;
          //查询页大于总页数，重新查询
          let cu =
            this.page.total % this.page.size == 0
              ? parseInt(this.page.total / this.page.size)
              : parseInt(this.page.total / this.page.size + 1);
          if (cu > 0 && this.page.current > cu) {
            this.queryList({ current: cu });
          }
        } else {
          this.$message({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //tab切换
    handleClick (tab, event) {
      console.log(tab, event);
      if (tab.label == "接口") {
        this.queryForm.type = 1;
      } else if (tab.label == "框架") {
        this.queryForm.type = 2;
      } else if (tab.label == "会员") {
        this.queryForm.type = 3;
      } else if (tab.label == "加量包") {
        this.queryForm.type = 4;
      }
      this.queryList();
    },
    //状态格式化
    statusFmt (row) {
      let name = "";
      if (row.status == "1") {
        name = "未支付";
      } else {
        name = "已支付";
      }
      return name;
    },
    //订单详情
    orderInfoHandler (id) {
      this.orderInfoVisible = true
      this.$nextTick(() => {
        this.$refs.orderInfo.init(id)
      })
    },
    //去支付
    payHandler (id) {
      this.$router.push({ name: "pay", query: { orderId: id } });
    },
  },
};
</script>
<style lang='scss' scoped>
.content {
  width: 100%;
  .data {
     padding: 20px 20px 40px;
      background: #fff;
    // border: thin solid rgba(0, 0, 0, 0.12);
    .inner {
      // max-height: window.innerHeight * 0.8;
      margin-bottom: 20px;
      // border: 1px solid #eee;
      .col-name {
        margin-right: 20px;
        color: #999;
        font-size: 14px;
        width: 7%;
      }
      .col-content {
        span {
          font-size: 14px;
          color: #666;
          font-weight: bold;
        }
      }
    }
  }
}

em {
  position: relative;
  &.success {
    color: $success;
    &::before {
      content: '已认证';
    }
  }
  &.error {
    color: $danger;
    &::before {
      content: '未认证';
    }
  }
}

@media screen and (min-width: 992px) {
  .content {
    .part1 {
      .col-md9 {
        margin-bottom: 0;
      }
      .price {
        margin-right: 20px;
      }
    }
  }
}
</style>