<!--  -->
<template>
  <div>
    <el-drawer
      :visible.sync="drawer"
      direction="rtl"
      :before-close="handleClose"
      size="400px"
      custom-class="drawer-box"
      title="详情"
    >
      <div class="drawer-inner">
        <ul class="list">
          <li class="line">
            订单号：
            <p>
              {{orderInfos.orderSn}}
              <fortress-copy :copyData="orderInfos.orderSn"></fortress-copy>
            </p>
          </li>
          <li class="line">
            产品名称：
            <p>{{orderInfos.shopName}}</p>
          </li>
          <li class="line">
            创建时间：
            <p>{{orderInfos.createTime}}</p>
          </li>
          <li class="line">
            状态：
            <p>
              <el-tag size="small" v-if="orderInfos.status==1" type="danger">未支付</el-tag>
              <el-tag size="small" v-else type="success">已支付</el-tag>
            </p>
          </li>
          <li class="line">
            实付金额：
            <p>{{orderInfos.payPrice}}元</p>
          </li>
          <li class="line">
            订单金额：
            <p>{{orderInfos.orderPrice}}元</p>
          </li>
        </ul>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { orderInfo } from "@/api/order.js";
export default {
  data () {
    return {
      drawer: true,
      orderInfos: {}
    };
  },

  components: {},

  computed: {},

  methods: {
    init (id) {
      this.drawer = true
      orderInfo(id).then(res => {
        this.orderInfos = res.data
      })
    },
    handleClose (done) {
      done()
    }
  }
}

</script>
<style lang='scss' scoped>
::v-deep .el-drawer__wrapper {
  .drawer-box {
    .el-drawer__header {
      margin-bottom: 20px;
      padding: 10px 20px;
      font-size: 16px;
      color: #666;
      border-bottom: 1px solid #e8eaec;
    }
  }
}

.drawer-inner {
  padding: 0 15px;
  font-size: 14px;
  color: #666;
  .list {
    .line {
      padding: 0px 0 20px;
      p {
        color: #999;
        padding: 10px 0;
        // border-bottom: 1px solid #e8eaec;
      }
    }
  }
}
</style>